<template>
  <navigation index="marketQuick" ref="navigation">
    <div class="main-wrapper" style="margin: 5px">
      <div
        class="topnavigation"
        style="
          height: 60px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <el-button type="primary" @click="GetMarketDetail('MarketDetail')"
          > {{  tools.GetLanguageValue('web.新增') }}</el-button
        >

        <div style="display: flex; align-items: center; gap: 10px;margin-right: 10PX;">
          <el-date-picker
            @change="ChangeDateSearch"     value-format="YYYY/MM/DD HH:mm:ss"
            v-model="SearchDate"
            type="datetimerange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
          />

          <el-select
            v-model="MakertPadding.Where.OrderStatus"
            multiple
            placeholder="Select" collapse-tags  :max-collapse-tags="3"
            style="width: 240px" @change="ChangeOrderStatus"
          >
            <el-option
              v-for="item in OrderStatus"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key"
            />
          </el-select>
 
        </div>
      </div>
      <div :style="{ height: ContentHeight + 'px' }">
        <el-table border :data="MakertDatas" style="width: 100%; height: 100%">
          <el-table-column type="index" width="50"> </el-table-column> 
          <el-table-column :label=" tools.GetLanguageValue('web.订单号') " width="180" prop="OrderNumber" /> 
          <el-table-column
          :label=" tools.GetLanguageValue('web.订单状态') "
            width="100"
            prop="OrderStatusFormate"
          >
            <template #default="scope">
              <el-tag v-if="scope.row.OrderStatus == 0">
                {{ scope.row.OrderStatusFormate }}
              </el-tag>

              <!-- 200 已确认 -->
              <el-tag v-if="scope.row.OrderStatus == 200" type="danger">
                {{ scope.row.OrderStatusFormate }}
              </el-tag>

              <!-- 300 已发货 -->
              <el-tag v-if="scope.row.OrderStatus == 300" type="warning">
                {{ scope.row.OrderStatusFormate }}
              </el-tag>

              <!-- 400 已完成 -->
              <el-tag v-if="scope.row.OrderStatus == 400" type="success">
                {{ scope.row.OrderStatusFormate }}
              </el-tag>

              <!-- 100 预定 -->
              <el-tag v-if="scope.row.OrderStatus == 100" type="info">
                {{ scope.row.OrderStatusFormate }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label=" tools.GetLanguageValue('web.数量') " width="100" prop="ItemCount" />
          <el-table-column :label=" tools.GetLanguageValue('web.创建人') " width="100" prop="CreateUserName" />
          <el-table-column :label=" tools.GetLanguageValue('web.客户姓名') " width="100" prop="ClientName" />
          <el-table-column :label=" tools.GetLanguageValue('web.金额') " width="100" prop="AmountFormate" />
          <el-table-column :label=" tools.GetLanguageValue('web.税额') " width="100" prop="TaxAmountFormate" />
          <el-table-column :label=" tools.GetLanguageValue('web.合计') " width="100" prop="TotalFormate" />
          <!-- <el-table-column label="创建人" width="180" prop="CreateUserName" /> -->
          <el-table-column
          :label=" tools.GetLanguageValue('web.创建时间') "
            width="180"
            prop="CreateTimeFormat"
          />

          <el-table-column :label=" tools.GetLanguageValue('web.操作') " width="280" fixed="right">
            <template #default="scope">
              <el-button
                type="success"
                size="small" 
                @click="GetMarketDetail(scope.row.Id)"
                > {{  tools.GetLanguageValue('web.管理订单') }}</el-button
              >
              <el-button
                type="primary"
                size="small" :disabled="scope.row.OrderStatus == 400"
                @click="AccomplishOrder(scope.row)"
                > {{  tools.GetLanguageValue('web.完成订单') }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style="margin: 5px">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentChange"
          :page-size="MakertPadding.Size"
          :total="MakertPadding.Count"
        >
        </el-pagination>
      </div>
    </div>
  </navigation>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import PictureCover from "../../../components/PictureCover.vue";
import { ElMessageBox, ElMessage } from "element-plus";

export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      tools:tools,
      MakertOpt: {
        OrderType: 0,
      },
      MakertDatas: [],

      SearchDate: [], //搜索时间
      MakertPadding: {
        Where: {},
        Page: 1,
        Size: 30,
        Count: 0,
      },

      ContentHeight: 900,
      MakertItemHeight: 900,
      OrderStatus: [], //订单状态
    };
  },
  name: "App",
  mounted() {
    this.GetMakertList();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.MakertItemHeight = document.documentElement.clientHeight - 370;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
    this.MakertItemHeight = this.clientHeight - 370;
    this.GetOrderStatusEnum();
  },

  components: {
    navigation,
    PictureCover,
  },
  methods: {
    ChangeOrderStatus() { 
      this.GetMakertList();
    },
    ChangeDateSearch(item) {
      console.log(JSON.stringify(this.SearchDate));
      if (this.SearchDate == null) {
        this.MakertPadding.Where.StartTime = null;
        this.MakertPadding.Where.EndTime = null;
      } else {
        this.MakertPadding.Where.StartTime = this.SearchDate[0];
        this.MakertPadding.Where.EndTime = this.SearchDate[1];
      }

      this.GetMakertList();
    },

    //获取订单状态
    GetOrderStatusEnum() {
      axios.apiMethod(
        "/sys/SystemData/GetOrderStatusEnum",
        "GET",
        {},
        (result) => {
          this.OrderStatus = result.Data;
        }
      );
    },

    //完成订单
    AccomplishOrder(item) {
      ElMessageBox.confirm(
        "确定要完成<span style='color:red;font-size:14pt'>[" +
          item.OrderNumber +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/WorkbenchMarket/AccomplishOrder",
            "post",
            item,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetMakertList();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: result.Data.Msg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },

    //获取销售单详细
    GetMarketDetail(orderId) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetMarketDetail",
        "get",
        {
          orderId: orderId,
        },
        (result) => {
          if (!result.Data.IsSuccess) {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
            });
          }
          this.$router.push({
            path: "MarketDetail",
            query: { id: result.Data.Model.Id },
          });
        }
      );
    },
    CurrentChange(number) {
      this.MakertPadding.Page = number;
      this.GetMakertList();
    },
    //获取销售单列表
    GetMakertList() {
      console.log(JSON.stringify(this.MakertPadding.Where.OrderStatus));
 
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetOrderList",
        "post",
        this.MakertPadding,
        (result) => {
          this.MakertDatas = result.Data.Datas;
          this.MakertPadding.Page = result.Data.Page;
          this.MakertPadding.Size = result.Data.Size;
          this.MakertPadding.Count = result.Data.Count;
        }
      );
    },
  },
};
</script>
  
<style scoped lang="less">
.el-button--text {
  margin-right: 15px;
}

.el-select {
  width: 300px;
}

.el-input {
  width: 300px;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.dialog-footer {
  height: 50px;
  position: absolute;
  bottom: 1px;
  width: calc(100% - 30px);
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}

.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
  