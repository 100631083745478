<template>
  <el-upload
    class="avatar-uploader"
    :action="UploadPictureImageUrl"
    :show-file-list="false"
    :on-success="UploadSuccess"
    :before-upload="UploadSuccess"
    :headers="headersImage"
  >
    <img v-if="ImageFormet" :src="ImageFormet" class="avatar" />
    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<script>
import axios from "../commons/AxiosMethod.js"
import { ElMessageBox } from "element-plus"

import {
  Expand,
  Avatar,
  Plus,
  ZoomIn,
  Download,
  Delete,
  UploadFilled
} from "@element-plus/icons-vue"

export default {
  data () {
    return {
      UploadImageUrl: "",
      headersImage: "",
      ComImageFormet: "",
      ComImageUrl: ""
    }
  },
  name: "App",
  props: {
    ImageUrl: "",
    ImageFormet: ""
  },
  components: {
    Expand,
    Avatar,
    Plus,
    ZoomIn,
    Download,
    Delete,
    UploadFilled
  },

  mounted () {
    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"
    this.UploadPictureImageUrl =
      axios.GetUrl() + "/api/Upload/ImageFile"
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }
  },
  methods: {
    // 上传成功操作
    UploadSuccess (response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName
        this.ComImageFormet = response.Data.ImageFormet
        // this.$emit("Image", this.ImageUrl);

        this.$emit("update:ImageFormet", this.ComImageFormet)
        this.$emit("update:modelValue", this.ComImageUrl)
        this.$emit("update:ImageModel", response.Data)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  border: 1px dashed #666;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>

